import Twtr from "./images/twt.png";
import LkdIn from "./images/in.png";
import Git from "./images/git.png";

function Header() {
  const openTwitter = () => {
    window.open("https://twitter.com/Michael_AdrianT");
  };

  const openLinkedIn = () => {
    window.open("https://www.linkedin.com/in/tohaneanumihai/");
  };

  const openGitHub = () => {
    window.open("https://github.com/fauxir");
  };

  return (
    <div className="h-fit w-full flex items-center justify-center flex-col pt-5">
      <div className="lg:flex lg:w-5/6 lg:justify-between">
        <div className="z-40 flex justify-center items-center lg:justify-between lg:ml-2">
          <span className="text-white text-2xl font-semibold">
            michaeladrian
          </span>
        </div>

        <div className="flex justify-between w-40 mt-6 lg:mt-0 z-20 items-center lg:mr-10">
          <img
            className="w-7 h-7 cursor-pointer hover:animate-pulse"
            onClick={openTwitter}
            src={Twtr}
            alt="Twitter logo"
          ></img>

          <img
            className="w-7 h-7 cursor-pointer hover:animate-pulse"
            onClick={openLinkedIn}
            src={LkdIn}
            alt="LinkedIn logo"
          ></img>

          <img
            className="w-7 h-7 cursor-pointer hover:animate-pulse"
            onClick={openGitHub}
            src={Git}
            alt="Git logo"
          ></img>
        </div>
      </div>
    </div>
  );
}

export default Header;
