import ChartComp from "./images/expense_component.png";
import { motion } from "framer-motion";
import { useState } from "react";

function ProjectCompI() {

    const [isOpenI, setIsOpenI] = useState(false);

    return ( 
        <div className="w-72 sm:w-96 mt-10 lg:w-full">
        <img src={ChartComp} alt=""></img>
        <div>
          <h1 className="text-white font-bold text-2xl mt-8">
            EasyBank landing page
          </h1>
          <div className="flex justify-between w-60">
            <div className="text-gray-400 font-semibold text-md mt-4">React</div>
            <div className="text-gray-400 font-semibold text-md mt-4">Tailwind</div>
            <div className="text-gray-400 font-semibold text-md mt-4">JSX</div>
            <div className="text-gray-400 font-semibold text-md mt-4">Redux</div>
          </div>
          <div className="flex justify-start mt-6">
            <motion.div
              transition={{ layout: { duration: 1, type: "spring" } }}
              layout
              onClick={() => setIsOpenI(!isOpenI)}
              className="flex items-center flex-col mr-10 cursor-pointer hover:animate-pulse"
            >
              <motion.span
                layout="position"
                className="font-bold text-white text-md underline-offset-10"
              >
                PROJECT DETAILS
              </motion.span>
              {isOpenI && (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 1 }}
                >
                  <p className="text-white">
                    Expense chart component completed with React, Tailwind CSS, Vite, and Redux for easy state management. The purpose for this project was to provide and easy way to visualise your spending troughout the week. The project was design by me start to finnish. The design was inspired by FrontEndMentor.
                  </p>
                </motion.div>
              )}
              <div className="bg-shamrock-500 w-full h-0.5 mt-3"></div>
            </motion.div>
            {isOpenI ? null : (
              <div className="flex items-center flex-col cursor-pointer hover:animate-pulse">
                <a
                  href="https://clinquant-cuchufli-eb60b2.netlify.app"
                  className="font-bold text-white text-md underline-offset-10"
                >
                  LIVE PROJECT
                </a>
                <div className="bg-shamrock-500 w-full h-0.5 mt-3"></div>
              </div>
            )}
          </div>
        </div>
      </div>
     );
}

export default ProjectCompI;