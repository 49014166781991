import ProjectCompI from "./ProjectCompI";
import ProjectCompII from "./ProjectCompII";
import ProjectCompIII from "./ProjectCompIII";
import ProjectCompIV from "./ProjectCompIV"

function Projects() {
  return (
    <div className="flex justify-center items-center flex-col mt-28 ">
    

      <div className="flex w-72 sm:w-96 justify-between mt-20` items-center lg:w-5/6">
        <h1 className="text-white lg:mr-72 font-semibold text-3xl lg:text-6xl">
          Projects
        </h1>
        <div className="flex items-center lg:ml-72 lg:pl-6 flex-col cursor-pointer hover:animate-pulse">
          <a className="font-bold text-white text-md underline-offset-10" href="./images\cv_mihai_tohaneanu.pdf" download>
            SEE MY CV
          </a>
          <div className="bg-shamrock-500 w-full h-0.5 mt-1"></div>
        </div>
      </div>
      <div className="lg:flex lg:justify-center lg:w-5/6">
        <div className="lg:grid lg:grid-cols-2 lg:w-fit lg:gap-x-96">
          
          <ProjectCompII />
          <ProjectCompIII />
          <ProjectCompIV />
        </div>
      </div>
    </div>
  );
}

export default Projects;
