import EasyBank from "./images/easy_bank.png";
import { motion } from "framer-motion";
import { useState } from "react";

function ProjectCompI() {

    const [isOpenI, setIsOpenI] = useState(false);

    return ( 
        <div className="w-72 sm:w-96 mt-10 lg:w-full">
        <img src={EasyBank} alt=""></img>
        <div>
          <h1 className="text-white font-bold text-2xl mt-8">
            EasyBank landing page
          </h1>
          <div className="flex justify-between w-32">
            <div className="text-gray-400 font-semibold text-md mt-4">HTML</div>
            <div className="text-gray-400 font-semibold text-md mt-4">CSS</div>
            <div className="text-gray-400 font-semibold text-md mt-4">JS</div>
          </div>
          <div className="flex justify-start mt-6">
            <motion.div
              transition={{ layout: { duration: 1, type: "spring" } }}
              layout
              onClick={() => setIsOpenI(!isOpenI)}
              className="flex items-center flex-col mr-10 cursor-pointer hover:animate-pulse"
            >
              <motion.span
                layout="position"
                className="font-bold text-white text-md underline-offset-10"
              >
                PROJECT DETAILS
              </motion.span>
              {isOpenI && (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 1 }}
                >
                  <p className="text-white">
                    EasyBank landing page completed with vanilla Javascript, HTML and CSS. The main purpose exercise the basics of each language. All the assests and design for the website we're provided by FontEndMentor.  
                  </p>
                </motion.div>
              )}
              <div className="bg-shamrock-500 w-full h-0.5 mt-3"></div>
            </motion.div>
            {isOpenI ? null : (
              <div className="flex items-center flex-col cursor-pointer hover:animate-pulse">
                <a
                  href="https://soft-ganache-fcd2e3.netlify.app/?fbclid=IwAR1wVJJ34TEzaYApOTSRCpW-H6E_vtCuKL_TSyR0DO3gBMq0SuPLN73A5-s"
                  className="font-bold text-white text-md underline-offset-10"
                >
                  LIVE PROJECT
                </a>
                <div className="bg-shamrock-500 w-full h-0.5 mt-3"></div>
              </div>
            )}
          </div>
        </div>
      </div>
     );
}

export default ProjectCompI;