import Twtr from "./images/twt.png";
import LkdIn from "./images/in.png";
import Git from "./images/git.png";

function Footer() {
  const openTwitter = () => {
    window.open("https://twitter.com/Michael_AdrianT");
  };

  const openLinkedIn = () => {
    window.open("https://www.linkedin.com/in/tohaneanumihai/");
  };

  const openGitHub = () => {
    window.open("https://github.com/fauxir");
  };

  const openMotto = () => {
    window.open(
      "https://i.pinimg.com/originals/63/82/fe/6382feca07c85d9366b921d64742649e.png"
    );
  };

  return (
    <div className="h-fit w-full flex items-center justify-center flex-col lg:flex-row pt-10 mt-16 pb-10 bg-zinc-800 lg:justify-center">
      <div className="h-fit w-full flex items-center justify-center flex-col lg:flex-row lg:w-5/6 lg:justify-between">
        <div className="z-40 flex flex-col items-center mb-4 lg:flex-row lg:mb-0">
          <span className="text-white text-2xl mb-3 lg:mb-0 font-semibold">
            michaeladrian
          </span>
        </div>

        <span
          onClick={openMotto}
          className="text-gray-400 text-md font-semibold cursor-pointer hover:animate-pulse hover:text-shamrock-500"
        >
          "per aspera ad astra"
        </span>

        <div className="flex justify-between w-40 mt-6 lg:mt-0 z-20 lg:ml-1">
          <img
            className="w-7 h-7 cursor-pointer hover:animate-pulse"
            onClick={openTwitter}
            src={Twtr}
            alt="Twitter logo"
          ></img>
          <img
            className="w-7 h-7 cursor-pointer hover:animate-pulse"
            onClick={openLinkedIn}
            src={LkdIn}
            alt="LinkedIn logo"
          ></img>
          <img
            className="w-7 h-7 cursor-pointer hover:animate-pulse"
            onClick={openGitHub}
            src={Git}
            alt="Git logo"
          ></img>
        </div>
      </div>
    </div>
  );
}

export default Footer;
