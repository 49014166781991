import HsP from "./images/Headshot.png";
import Circles from "./images/website_assets.png";
import Experience from "./Experience";

function Hero() {
  return (
    <div className="w-full flex justify-center items-center flex-col">
      <div className="h-fit w-full flex justify-center mt-3 lg:absolute lg:top-20 lg:justify-end">
        <div className="w-full h-72 absolute flex justify-end items-end mt-3.5 lg:z-10">
          <img
            className=" h-32 w-auto opacity-25 absolute top-4 -left-40 lg:h-40 lg:-left-20 lg:z-10"
            src={Circles}
            alt=""
          ></img>
          <div className="h-fill w-16 overflow-hidden">
            <div className="border-2 h-36 w-36 rounded-full border-gray-300 opacity-70 mt-7 lg:h-40 lg:w-40"></div>
          </div>
        </div>
      </div>

      <div className="w-5/6 ">
        <div className="mt-8 flex justify-center items-center flex-col lg:flex-row-reverse lg:justify-between">
          <div className="w-72 sm:w-96 flex items-end justify-center lg:w-2/4">
            <img className="w-56 z-10 lg:w-full lg:px-6" src={HsP} alt=""></img>
            <div className=" bg-zinc-800 w-56 h-full bg-opacity-30 absolute z-0 lg:w-1/3 lg:"></div>
          </div>

          <div className="lg:w-4/6">
            <div className="flex justify-center items-center flex-col mt-16 lg:items-start lg:mt-0">
              <div className="lg:z-50">
                <h1 className="text-white text-3xl sm:text-4xl font-semibold lg:text-8xl">
                  Nice to meet you!
                </h1>
              </div>
              <div>
                <h1 className="text-white text-3xl sm:text-4xl font-semibold lg:text-8xl lg:z-50 ">
                  I'm
                  <span> </span>
                  <span className="underline font-semibold">
                    Michael Adrian
                  </span>
                  .
                </h1>
              </div>
              <div className="w-5/6 lg:w-4/6">
                <h3 className="text-gray-400 text-center text-xl mt-8 font-semibold lg:text-left">
                  Forward-thinking Aberdeen, UK based Frontend React developer
                  who is passionate about developing elegant and user-friendly
                  web apps.
                </h3>
              </div>

              <div className="mt-10 flex items-center flex-col cursor-pointer hover:animate-pulse">
                <a
                  className="font-semibold text-white text-xl underline-offset-10"
                  href="mailto:fauxir@gmail.com"
                >
                  CONTACT ME
                </a>
                <div className="bg-shamrock-500 w-full h-1 mt-4"></div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full flex justify-center">
          <div className="h-px w-5/6 bg-slate-400 mt-20 lg:w-full"></div>
        </div>
      </div>

      <Experience />

      <div className="w-full flex justify-center">
        <div className="lg:hidden h-px w-5/6 bg-slate-400 mt-10"></div>
      </div>
    </div>
  );
}

export default Hero;
