import React from "react";
import Header from "C:/Users/Fauxir/Desktop/Portofolio/Main_portofolion/my-portofolio/main_portfolio/src/components/Header";
import Hero from "C:/Users/Fauxir/Desktop/Portofolio/Main_portofolion/my-portofolio/main_portfolio/src/components/Hero";
import Projects from "C:/Users/Fauxir/Desktop/Portofolio/Main_portofolion/my-portofolio/main_portfolio/src/components/Projects";
import Footer from "C:/Users/Fauxir/Desktop/Portofolio/Main_portofolion/my-portofolio/main_portfolio/src/components/Footer";

function App() {
  return (
    <div className="bg-neutral-900 h-full">
      <div className="">
        <Header />
        <Hero />
        <Projects />
        <Footer />
      </div>
    </div>
  );
}

export default App;
