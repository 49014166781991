import Circles from "./images/website_assets.png";

function Experience() {
  return (
    <div className="flex justify-center lg:w-5/6">
      <div className="mt-4 lg:grid lg:grid-cols-3 lg:gap-x-48 lg:gap-y-10 lg:w-full">
        <div className="lg:ml-3">
          <h1 className="text-white text-center mt-6 font-semibold text-3xl">
            HTML
          </h1>
          <h3 className="text-gray-400 text-center font-semibold text-xl mt-2">
            1 Year Experince
          </h3>
        </div>
        <div>
          <h1 className="text-white text-center mt-6 font-semibold text-3xl">
            CSS
          </h1>
          <h3 className="text-gray-400 text-center font-semibold text-xl mt-2">
            1 Year Experince
          </h3>
        </div>
        <div className="lg:mr-3">
          <h1 className="text-white text-center mt-6 font-semibold text-3xl">
            JAVASCRIPT
          </h1>
          <h3 className="text-gray-400 text-center font-semibold text-xl mt-2">
            1 Year Experince
          </h3>
        </div>
        <div className="lg:ml-3">
          <h1 className="text-white text-center mt-6 font-semibold text-3xl">
            REACT
          </h1>
          <h3 className="text-gray-400 text-center font-semibold text-xl mt-2">
            1 Year Experince
          </h3>
        </div>
        <div>
          <h1 className="text-white text-center mt-6 font-semibold text-3xl">
            TYPESCRIPT
          </h1>
          <h3 className="text-gray-400 text-center font-semibold text-xl mt-2">
            1 Year Experince
          </h3>
        </div>
        <div className="lg:mr-3">
          <h1 className="text-white text-center mt-6 font-semibold text-3xl">
            TAILWIND
          </h1>
          <div className="w-full h-40 absolute overflow-hidden left-0">
            <img
              className=" h-32 w-auto opacity-25 absolute -right-36 bottom-4 lg:h-36"
              src={Circles}
              alt=""
            ></img>
          </div>
          <h3 className="text-gray-400 text-center font-semibold text-xl mt-2">
            1 Year Experince
          </h3>          
        </div>   
      </div>
    </div>
  );
}

export default Experience;
